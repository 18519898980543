import "core-js/stable";
import "regenerator-runtime/runtime";

import _has from 'lodash/has';
import App from '@/App';
import config from '@/config'
import { detect } from 'detect-browser';
import Dropdown from 'bootstrap/js/dist/dropdown'; // importing here so it can build inside the main app chunk, it will be grayed out
import mitt from 'mitt';
import Rollbar from 'rollbar';
import router from '@/router'
import store from '@/store'
import styles from '@/assets/styles/app.scss';
import Vue from 'vue';
import VueGtag from "vue-gtag";				// https://github.com/MatteoGabriele/vue-gtag

import VueDebounce from 'vue-debounce';
Vue.use(VueDebounce);

import 'office-ui-fabric-js/dist/css/fabric.min.css';
import 'office-ui-fabric-js/dist/css/fabric.components.css';
import 'office-ui-fabric-js/dist/js/fabric.js';

const emitter = mitt();

if(!config.isDev){
	console.log(VERSION);
}

// Rollbar Config
if(config.useErrorLogger){
	Vue.prototype.$rollbar = new Rollbar({
		accessToken: 'a760930638354ae5917524dc6f441273',
		captureUncaught: true,
		captureUnhandledRejections: true,
		enabled: true,
		environment: (config.isLive) ? "production" : "staging",
		filterTelemetry: function(e) {
			return e.type === 'network'
				&& e.body.subtype === 'xhr'
				&& (
					e.body.url.indexOf('https://bam') === 0 ||
					e.body.url.indexOf('https://stats') === 0 ||
					e.body.url.indexOf('https://www.google') === 0 || 
					e.body.url.indexOf('https://ekr.zdassets') === 0 
				);
		},
		hostSafeList: ['perrlacomplete.com', 'perrla.com'],
		// Characters that may need to be escaped include: ^, $, ., |, ?, *, +, (), [], and {}. In order for these to remain escaped in the regex matcher, they should be doubled escaped when passed in the config.
		ignoredMessages: [
			"AccessDenied",
			"An internal error has occurred.",
			"Cannot read properties of undefined \\(reading 'isSetSupported'\\)",
			"Cannot set properties of undefined \\(setting 'status'\\)",
			"Can't find variable Office",
			"GeneralException",
			"InvalidArgument",
			"Item sent with null or missing arguments.",
			"ItemNotFound",
			"Neither the locale, en-us, provided by the host app nor the fallback locale en-us are supported\\.",
			"MicrosoftAjax\\.js is not loaded successfully\\.",
			"Office is not defined",
			"ResizeObserver loop limit exceeded",
			"Script error\\.",
			"Strings is not defined",
			"Unable to set property 'status' of undefined or null reference",
			"Unable to get property 'id' of undefined or null reference",
			"Unexpected token '\\.'",
		],
		payload: {
			client: {
				code_version: VERSION,
			},
		},
		source_map_enabled: (config.isLive) ? true : false,
		verbose: true,
	});
}

Vue.config.errorHandler = (error, vm, info) => {
	if(config.useErrorLogger){
		vm.$rollbar.error(error);
	} else {
		console.group('Vue Error');
		console.log(error);
		console.log(info);
		console.groupEnd();
	}
	throw error; // rethrow
}


// Register a global custom directive called `v-focus`
Vue.directive('focus', {
	// When the bound element is inserted into the DOM...
	inserted: function (el) {
		// Focus the element
		el.focus();
	}
});

// Adds Target Blank to a link inside reference output
Vue.directive('add-blank-target', {
	inserted(el){
		// regEx for href conetent
		const isLinkInContent = RegExp('href="([^\'\"]+)');
		
		// Only if there is a link in the content
		if(isLinkInContent.test(el.innerHTML)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(el.innerHTML, 'text/html');
			let aElements = parsedHtml.getElementsByTagName("a");
			
			// double check to make sure this is really an <a>
			if(aElements && aElements[0]){
				try {
					let newUrl = window.decodeURIComponent(aElements[0].getAttribute('href'));
					newUrl = newUrl.trim().replace(/\s/g, "");
					if(/^(:\/\/)/.test(newUrl)){
						newUrl = `http${newUrl}`;
					}
					if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
						newUrl = `http://${newUrl}`;
					}

					aElements[0].setAttribute('href', newUrl);
				} catch {
					console.log('decodeURIComponent failed 1');
					console.log(aElements[0]); // https://www.florida-demographics.com/pasco-county-demographics#:~:text=Race&Ethnicity2020,%)andBlack(5.6%).
				}
			}
			el.innerHTML = parsedHtml.body.innerHTML;
		}
	},
	update(el) {
		// regEx for href content
		const isLinkInContent = RegExp('href="([^\'\"]+)');
		
		// Only if there is a link in the content
		if(isLinkInContent.test(el.innerHTML)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(el.innerHTML, 'text/html');
			let aElements = parsedHtml.getElementsByTagName("a");
			
			// double check to make sure this is really an <a>
			if(aElements && aElements[0]){
				try {
					let newUrl = window.decodeURIComponent(aElements[0].getAttribute('href'));
					newUrl = newUrl.trim().replace(/\s/g, "");

					if(/^(:\/\/)/.test(newUrl)){
						newUrl = `http${newUrl}`;
					}
					if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
						newUrl = `http://${newUrl}`;
					}
					// console.log('newUrl');
					// console.log(newUrl);
					aElements[0].setAttribute('href', newUrl);
				} catch {
					console.log('decodeURIComponent failed 2');
					console.log(aElements[0]); // https://www.florida-demographics.com/pasco-county-demographics#:~:text=Race&Ethnicity2020,%)andBlack(5.6%).
				}
			}
			el.innerHTML = parsedHtml.body.innerHTML;
		}
	}
});


// Convert Config Settings to Vue Instance Properties (to use in vue templates)
Vue.prototype.emitter = emitter;
Vue.prototype.$enums = config.enums;
Vue.prototype.$isDev = config.isDev;
Vue.prototype.$isTest = config.isTest;
Vue.prototype.$isLive = config.isLive;
Vue.prototype.$platformId = config.platformId;
Vue.prototype.$version = VERSION;

const browser = detect();
let os = '';

const clientStrings = [
	{s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
	{s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
	{s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
	{s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
	{s:'Windows Vista', r:/Windows NT 6.0/},
	{s:'Windows Server 2003', r:/Windows NT 5.2/},
	{s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
	{s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
	{s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
	{s:'Windows 98', r:/(Windows 98|Win98)/},
	{s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
	{s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
	{s:'Windows CE', r:/Windows CE/},
	{s:'Windows 3.11', r:/Win16/},
	{s:'Android', r:/Android/},
	{s:'Open BSD', r:/OpenBSD/},
	{s:'Sun OS', r:/SunOS/},
	{s:'Chrome OS', r:/CrOS/},
	{s:'Linux', r:/(Linux|X11(?!.*CrOS))/},
	{s:'iOS', r:/(iPhone|iPad|iPod)/},
	{s:'Mac OS X', r:/Mac OS X/},
	{s:'Mac OS', r:/(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
	{s:'QNX', r:/QNX/},
	{s:'UNIX', r:/UNIX/},
	{s:'BeOS', r:/BeOS/},
	{s:'OS/2', r:/OS\/2/},
	{s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
];
for (let id in clientStrings) {
	let cs = clientStrings[id];
	if (cs.r.test(navigator.userAgent)) {
		os = cs.s;
		break;
	}
}

let osVersion = '';
if (/Windows/.test(os)) {
	osVersion = /Windows (.*)/.exec(os)[1];
	os = 'Windows';
}

let OSVersionOutput = os + ' ' + osVersion;

/*
// Add to Vue properties, so i can also call the same values after sync
(i don't like this, after this pass remove this and get the values from the store)
*/
Vue.prototype.$browser = browser.name || '';
Vue.prototype.$browserVersion = browser.version || '';
Vue.prototype.$os = (browser.os === 'Mac OS') ? 'MacOS' : 'Windows';
Vue.prototype.$osVersion = OSVersionOutput;
Vue.prototype.$sourceApp = 'WordAddIn';
Vue.prototype.$sourceAppVersion = Vue.prototype.$version;


// Google Analytics
if(config.isLive){
	Vue.use(VueGtag, {
		config: { id: "UA-8625624-8" },
		globalObjectName: 'PERRLAGtag',
		params: {
			send_page_view: false
		}
	}, router);
}

Office.onReady(function() {
	// Office is ready
	if (_has(Office, 'context') && _has(Office.context, 'requirements') && (Office.context.requirements.isSetSupported('DialogApi', '1.2') || Office.context.requirements.isSetSupported('WordApi', '1.1'))) {
		window.$vm = new Vue({
			render: ((createEL) => {
				return createEL(App);
			}),
			router,
			store,
		}).$mount('#app');
	} else {
		document.getElementById('loadError').style.display = 'block';
	}
});
