<template>
	<div :class="['sidemenu-wrp', {'open': isSidebarOpen}]">
		<div class="w-100 d-block">
			<a href="#" @click.prevent="closeMenu" class="close-sidemenu">
				<span class="svg bi-x-lg" title="Open Menu" /> CLOSE
			</a>

			<hr />

			<div v-if="showDevTools">
				<a href="#" v-show="showBetaFeatures" class="router-link" @click.prevent="setShowBetaFeatures(false)">
					Hide Beta Features
				</a>
				<a href="#" v-show="!showBetaFeatures" class="router-link" @click.prevent="setShowBetaFeatures(true)">
					Show Beta Features
				</a>
				<router-link :to="{name:'ErrorTests'}" class="router-link">
					Error Tests
				</router-link>
				<router-link :to="{name:'DeveloperTools'}" class="router-link">
					Developer Tools
				</router-link>
				
				<hr />
			</div>

			<div v-if="iOS">
				<router-link :to="{name:'Startup'}" class="router-link">
					Getting Started
				</router-link>
			</div>

			<router-link :to="{name: 'DocumentCreateLanding'}" class="router-link">
				Create New Paper
			</router-link>

			<hr />
			
			<router-link :to="{name: 'PaperSectionsTitlePage'}" class="router-link">
				Title <span v-if="documentType === $enums.DocumentType.RESEARCH_PAPER && (formatVersionID === $enums.Format.APA6 || formatVersionID === $enums.Format.APA7)">Page</span>
			</router-link>

			<router-link
				v-if="documentType === $enums.DocumentType.RESEARCH_PAPER" 
				class="router-link"
				key="linkAbstract" 
				:to="{name: 'PaperSectionsAbstract'}">Abstract</router-link>

			<a
				href="#"
				v-else 
				class="router-link disabled"
				key="linkNoAbstract"
				@click.prevent="">Abstract</a>

			<router-link
				v-if="documentType === $enums.DocumentType.RESEARCH_PAPER" 
				class="router-link"
				key="linkToc" 
				:to="{name: 'PaperSectionsToc'}">Table of Contents</router-link>

			<a
				href="#"
				v-else 
				class="router-link disabled"
				key="linkNoToc"
				@click.prevent="">Table of Contents</a>

			<router-link
				v-if="documentType === $enums.DocumentType.RESEARCH_PAPER" 
				class="router-link"
				key="linkAppendices" 
				:to="{name: 'PaperSectionsAppendix'}">Appendices</router-link>
			
			<a
				v-else 
				href="#"
				class="router-link disabled"
				key="linkNoAppendices"
				@click.prevent="">Appendices</a>
			
			<hr />

			<router-link :to="{name: 'ReferencesCitations'}" class="router-link">
				References &amp; Citations
			</router-link>

			<router-link
				:to="{
					name: 'ResearchNotes'
				}"
				class="router-link">
				Research Notes
			</router-link>

			<hr />

			<router-link :to="{name: 'TextStyles'}" class="router-link" v-if="iOS">
				Text &amp; Headings
			</router-link>
			
			<router-link
				v-if="documentType !== $enums.DocumentType.ANNOTATED_BIBLIOGRAPHY && documentType !== $enums.DocumentType.REFERENCE_LIST" 
				class="router-link"
				key="linkInsertFigure" 
				:to="{name: 'InsertFigure'}">Insert Figure</router-link>

			<a
				v-else 
				href="#"
				class="router-link disabled"
				key="linkNoInsertFigure"
				@click.prevent="">Insert Figure</a>

			<router-link
				v-if="documentType !== $enums.DocumentType.ANNOTATED_BIBLIOGRAPHY && documentType !== $enums.DocumentType.REFERENCE_LIST" 
				class="router-link"
				key="linkInsertTable" 
				:to="{name: 'InsertTable'}">Insert Table</router-link>

			<a
				v-else 
				href="#"
				class="router-link disabled"
				key="linkNoInsertTable"
				@click.prevent="">Insert Table</a>

			<hr />

			<router-link v-if="formatVersionID === $enums.Format.APA7" :to="{name: 'AdvancedEditing'}" class="router-link" key="linkAem">
				Advanced Editing
			</router-link>

			<a
				v-else 
				href="#"
				class="router-link disabled"
				key="linkNoAem"
				@click.prevent="">Advanced Editing</a>
			
			<hr />

			<div v-if="iOS">	
				<a href="https://perrla.zendesk.com/hc/en-us/categories/360001366714-PERRLA-Add-in-BETA-" target="_blank" rel="noopener noreferrer" class="router-link">Help</a>
			</div>

			<router-link :to="{name:'PaperReview'}" class="router-link">
				Paper Review
			</router-link>

			<router-link :to="{name:'Settings'}" class="router-link">
				Settings
			</router-link>
			
		</div>
	</div>
</template>

<script>
export default {
	name: 'SideMenu',
	computed:{
		documentType(){
			return this.$store.state.documentType;
		},
		formatVersionID(){
			return this.$store.state.formatVersionID;
		},
		iOS(){
			return this.$store.state.iOS;
		},
		isSidebarOpen(){
			return this.$store.state.isSidebarOpen;
		},
		showDevTools(){
			if(this.$isLive){
				return this.$store.state.customer.isImpersonating;
			} 
			return true;
		},
		showBetaFeatures:{
			get(){
				return this.$store.state.showBetaFeatures;
			},
			set(newValue){
				this.$store.commit('SHOW_BETA_FEATURES', newValue);
			}
		}
	},
	methods: {
		closeMenu(){
			this.$store.commit('isSidebarOpen', false);
		},
		setShowBetaFeatures(value){
			this.showBetaFeatures = value;
			this.closeMenu();
		},
	},
}
</script>

<style lang="scss" scoped>
@import './../assets/styles/vars_perrla.scss';

$menu-width: 225px;
@include color-mode(dark) {
	.sidemenu-wrp {
		background-color: $gray-800;

		ul{
			li {
				a{
					color: $dark-body-color;

					&:hover {
						background: $gray-700;
					}
				}
			}
		}
	}
}

@include color-mode(dark) {
		div.sidemenu-wrp {
			background: $dark-body-bg;	
			&.open {
				border-left-color: $secondary-500;
				border-right-color: $secondary-500;

				a {
					color: $dark-body-color;

					&.close-sidemenu {
						background-color: $gray-800;
					}
					&.router-link {
					
						&.disabled, &.disabled:hover {
							background: $gray-900 !important;
							color: $gray-700 !important;
						}
					}
				}
			}
		}
	}
	

div.sidemenu-wrp {
	background: $body-bg;
	bottom:0;
	left: -$menu-width;
	overflow-y: auto;
	position: fixed;
	top: 0;
	transition: left 0.25s;
	width: $menu-width;
	z-index: 2000;

	&.open {
		border-left: 1px solid $secondary-300;
		border-right: 1px solid $gray-400;
		box-shadow: $box-shadow;
		left:0;
	}

	a {
		color: $body-color;
		display: block;
		padding: 0.5rem;
		text-decoration: none;
		
		@media (max-height: 600px) {
			padding-bottom: 0.375rem;
			padding-top: 0.375rem;
		}

		&:hover, &.router-link-active{
			background: $primary !important;
			color:#fff !important;
		}
		
		&.close-sidemenu {
			background-color: $gray-100;
			font-size: 1rem;

			.svg {
				font-size: 1rem;
			}
		}
		&.router-link {
			font-size: 1rem;

			@media (max-height: 600px) {
				font-size: 0.9rem;
			}
			
			&.disabled, &.disabled:hover {
				background: #fff !important;
				color: $gray-300 !important;
				cursor: not-allowed !important;
			}
		}
	}//a

	hr{
		color: $secondary-300;
		margin: 0;
		opacity: 1;
	}
	
}
</style>