/*
- AS_InitTooltips
-- inits BS5 Tooltips
-- the Add-In version can't use this yet since some cases have IE11 still, which doesn't work with Popper.js or Bootstrap 5
*/

// import Tooltip from 'bootstrap/js/dist/tooltip';

export default () => {
	return new Promise((resolve) => {
		// console.log('AS_InitTooltips');

		// let the ui catch up so there will be a tooltip rendered
		// setTimeout(()=>{
		// 	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
		// 	const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
		// 		new Tooltip(tooltipTriggerEl, {
		// 			trigger : 'hover'
		// 		})
		// 	});

			resolve();
		// }, 500);

	});//e:Promise

}