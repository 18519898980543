/*
- Customer
-- Storing all customer data usually populated from Sync call
-- ex: this.$store.state.customer.x
-- 2023.11.17
*/

import _has from 'lodash/has';
import config from '@/config';

export default {
	namespaced: true,

	state:{
		// local storage states
		email:	'',			// auto-fill when creating a support ticket

		// local storage in PO only (not in the Add-In)
		fullName:	'',		// display in header dropdown menu
		themeId: 0,

		// non local storage states
		alternativeEmails: [],		// array
		calendarStartingDayOfWeek: 0,
		calendarStartingHour: 0,	// think of this as time zone offset
		calendarViewId: 0,
		expirationDate: '',
		firstName: '',
		hasPrioritySupport: false,
		id: 0,				// auto-fill when creating a support ticket
		institution: '',
		isCancelled: false,
		isImpersonating: false,
		isRecurring: false,
		lastName: '',
		organizationExpirationDate: null,
		organizationId: 0,
		organizationStatusId: 0,
		paperAutoSave: 0,
		renewalPeriod: 0,
		role: 0,
		timeZone: 0,
		
	},//e:state

	mutations: {
		PUSH_ONE_ALTERNATIVE_EMAIL(state, value) {
			// make sure this gets pushed to the front
			state.alternativeEmails.unshift(value);
		},
		SET_ALTERNATIVE_EMAILS(state, value) {
			state.alternativeEmails = value;
		},
		SET_CALENDAR_STARTING_DAY_OF_WEEK(state, value) {
			state.calendarStartingDayOfWeek = value;
		},
		SET_CALENDAR_STARTING_HOUR(state, value) {
			state.calendarStartingHour = value;
		},
		SET_CALENDAR_VIEW_ID(state, value) {
			state.calendarViewId = value;
		},
		SET_EMAIL(state, value) {
			state.email = value;
		},
		SET_EXPIRATION_DATE(state, value){
			state.expirationDate = value;
		},
		SET_FIRST_NAME(state, value){
			state.firstName = value;
		},
		SET_FULL_NAME(state, value) {
			state.fullName = value;
		},
		SET_HAS_PRIORITY_SUPPORT(state, value) {
			state.hasPrioritySupport = value;
		},
		SET_ID(state, value) {
			state.id = value;
		},
		SET_INSTITUTION(state, value) {
			state.institution = value;
		},
		SET_IS_CANCELLED(state, value) {
			state.isCancelled = value;
		},
		SET_IS_IMPERSONATING(state, value) {
			state.isImpersonating = value;
		},
		SET_IS_RECURRING(state, value) {
			state.isRecurring = value;
		},
		SET_LAST_NAME(state, value) {
			state.lastName = value;
		},
		SET_ORGANIZATION_EXPIRATION_DATE(state, value) {
			state.organizationExpirationDate = value;
		},
		SET_ORGANIZATION_ID(state, value) {
			state.organizationId = value;
		},
		SET_ORGANIZATION_STATUS_ID(state, value) {
			state.organizationStatusId = value;
		},
		SET_PAPER_AUTO_SAVE(state, value) {
			state.paperAutoSave = value;
		},
		SET_RENEWAL_PERIOD(state, value){
			state.renewalPeriod = value;
		},
		SET_ROLE(state, value) {
			state.role = value;
		},
		SET_THEME_ID(state, value) {
			state.themeId = value;
		},
		SET_TIME_ZONE(state, value) {
			state.timeZone = value;
		},
		
	},//e:mutations

	actions: {
		// dispatched inside a Sync call
		setSiteTheme({commit}, $opts) {
			return new Promise((resolve, reject) => {
				if(config.platformId === config.enums.Platform.ADD_IN){
					// Add-In sets the theme based on Word settings
					return resolve();

				} else if(config.platformId === config.enums.Platform.ONLINE){
					if(_has($opts, 'legacyThemeId')){
						// legacyThemeId is what comes in from the Sync call, it will be a 1 regular / 2 dark / 5 serrena (the other numbers do something too)
						// convert this id into something i can store here in the SPA 
						let convertedThemeId = config.enums.Theme.REGULAR;
	
						// convert this into that
						switch($opts.legacyThemeId){
							case 1:	// Normal
								document.body.classList.remove('theme-dark');
								document.body.classList.remove('theme-serrena');
								break;
							case 2:	// Dark
							case 4:	// ?
								convertedThemeId = config.enums.Theme.DARK;
								document.body.classList.add('theme-dark');
								document.body.classList.remove('theme-serrena');
								break;
							case 3:	// ?
							case 5:	// Serrena
								convertedThemeId = config.enums.Theme.SERRENA;
								document.body.classList.add('theme-serrena');
								document.body.classList.remove('theme-dark');
								break;
						}
	
						// save new value
						commit('SET_THEME_ID', convertedThemeId);
					}
	
					document.body.classList.add('platform-online');
					
					return resolve();
				}
			});//e:Promise
		},//e:setSiteTheme
	},//e:actions
}
