/*
- Api
-- Other than session id this one should only be for saving API level headers
-- I can't decide how this is different from Customer
-- ex: this.$store.state.api.x
-- 2022.11.14
*/

export default {
	namespaced: true,
	
	state: {
		// local storage states
		sessionId: '',
		token: '',	// the word 'token' might be creating the problem on the Live Add-In so overname it here to check and see

		// non local storage states
		browserName: '',
		browserVersion: '',
		customerId: '',
		osName: '',
		osVersion:'',
	},
	
	mutations: {
		SET_BROWSER_NAME(state, value)	{
			state.browserName = value;
		},
		SET_BROWSER_VERSION(state, value)	{
			state.browserVersion = value;
		},
		SET_CUSTOMER_ID(state, value)	{
			state.customerId = value;
		},
		SET_OS_NAME(state, value)	{
			state.osName = value;
		},
		SET_OS_VERSION(state, value)	{
			state.osVersion = value;
		},
		SET_SESSION_ID(state, value)	{
			state.sessionId = value;
		},
		SET_TOKEN(state, value) {
			state.token = value;
		},
	},
}