/*
- WordDocumentProps
-- get and set Word doc properties
*/

export default {
	// used to add a single prop $opts.key : $opts.value
	addCustomProp($opts){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				//set the doc prop
				context.document.properties.customProperties.add($opts.key, $opts.value);
	
				return context.sync().then(()=>{
					return resolve();
				});
			});//e:Word.run
		});//e:Promise
	},//e:addCustomProp

	// checks version to see if it's possible to set doc props - returns boolean
	canSetDocProps(){
		console.log("Platform: " + Office.context.platform);
		console.log("Version: " + Office.context.diagnostics.version);

		if(Office.context.platform === 'Mac' && Office.context.diagnostics.version.substr(0,5) === '16.27'){
			return false;
		}

		if(Office.context.platform === 'iOS' && Office.context.diagnostics.version.substr(0,4) === '2.27'){
			return false;
		}

		return true;
	},//e:canSetDocProps


	// used to get all custom doc props - returns object of keys: values
	getAllCustomProps(){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				let docProps = context.document.properties.customProperties;
				docProps.load("key,type,value");
				return context.sync().then(() => {
					let returnProps = {};
					// build object of key:value pairs from items
					docProps.items.forEach((item)=>{
						returnProps[item.key] = item.value;
					});
					resolve(returnProps);
				});
			});//e:Word.run
		});//e:Promise
	},//e:getAllCustomProps

	// used to get a single custom doc prop - returns the prop value only
	getCustomProp(propName){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				let docProps = context.document.properties.customProperties;
				return context.sync().then(()=>{
					let docPropsObject = docProps.getItemOrNullObject(propName);
					context.load(docPropsObject);
					return context.sync().then(() =>{
						if(typeof docPropsObject.value === 'undefined'){
							reject();
						} else {
							resolve(docPropsObject.value);	
						}
					});
				});
			});//e:Word.run
		});//e:Promise
	},//e:getCustomProp

	// used to get a single custom doc prop - returns the prop value only
	useAdvancedMode(){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				let docProps = context.document.properties.customProperties;
				return context.sync().then(()=>{
					let docPropsObject = docProps.getItemOrNullObject("UseAdvancedMode");
					context.load(docPropsObject);
					return context.sync().then(() =>{
						if(docPropsObject.value){
							resolve(docPropsObject.value);	
						} else {
							//if this isn't found it's ok, default to false
							resolve(false);
						}
					});
				});
			});//e:Word.run
		});//e:Promise
	},//e:useAdvancedMode

	// used to get a single custom doc prop - returns the prop value only
	useNewCitationMode(){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				let docProps = context.document.properties.customProperties;
				return context.sync().then(()=>{
					let docPropsObject = docProps.getItemOrNullObject("UseNewCitationMode");
					context.load(docPropsObject);
					return context.sync().then(() =>{
						if(docPropsObject.value){
							resolve(docPropsObject.value);	
						} else {
							//if this isn't found it's ok, default to false
							resolve(false);
						}
					});
				});
			});//e:Word.run
		});//e:Promise
	},//e:useAdvancedMode

	// used to get a single doc prop (not custom) - returns the prop value only
	getDocProp(propName){
		return new Promise((resolve, reject) => {
			Word.run((context) =>{
				let docProps = context.document.properties;
				return context.sync().then(()=>{
					context.load(docProps);
					return context.sync().then(() =>{
						if(docProps[propName]){
							resolve(docProps[propName]);	
						} else {
							reject();
						}
					});
				});
			});//e:Word.run
		});//e:Promise
	},//e:getDocProp

	// used to set a single prop $opts.key : $opts.value / returns an empty resolve
	setCustomProp($opts){
		return new Promise((resolve, reject) => {
			if(!$opts.key){
				return reject();
			}

			Word.run((context) =>{
				// https://stackoverflow.com/questions/44788002/word-add-in-how-to-read-custom-document-property
				context.document.properties.customProperties.add($opts.key, $opts.value);
				return context.sync().then(() =>{
					resolve();
				});
			});//e:Word.run
		});//e:Promise
	},//e:setCustomProp

	// used to set a single doc prop $opts.key : $opts.value
	setDocProp($opts){
		if(!$opts.key){
			return;
		}
		Word.run((context) =>{
			return context.sync().then(() => {
				context.document.properties[$opts.key] = $opts.value;
			});
		});//e:Word.run
	},//e:setDocProp

}//e:export