var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "toasterWrp", class: ["toaster", { open: _vm.isOpen }] },
    [
      _c("div", {
        class: ["toaster-header toaster-" + _vm.type],
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _vm._v(" "),
      _vm.body.length > 0
        ? _c("div", { staticClass: "toaster-body" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.body) } }),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  class: ["btn btn-" + _vm.buttonType],
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t")]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }