/*
- Lots of weird redirects here
-- Word can have an old manifest file, so any links in previous manifests should be redirected to their newer counterparts
*/
import config from '@/config';

export default [
	// Annotated Bibliography
	{
		name: 'CreateAnnotatedBibliography',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateAnnotatedBibliography'),
		path: '/create-annotated-bibliography/:paperFormatUrl',
		props: true,
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
			paperTypeEnum: config.enums.PaperType.APA7_AnnotatedBibliography,
		},
	},
	{
		path: '/create-v2-annotated-bibliography/15',
		redirect: {
			name: 'CreateAnnotatedBibliography',
			params: {
				'paperFormatUrl': 'apa-7'
			}
		}
	},

	// Discussion Post
	{
		name: 'CreateDiscussionPost',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateDiscussionPost'),
		path: '/create-discussion-post/:paperFormatUrl',
		props: true,
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
			paperTypeEnum: config.enums.PaperType.APA7_DiscussionPost,
		},
	},
	{
		path: '/create-v2-discussion-post/13',
		redirect: {
			name: 'CreateDiscussionPost',
			params: {
				'paperFormatUrl': 'apa-7'
			}
		}
	},
	{
		path: '/create-discussion-post/apa7',
		redirect: {
			name: 'CreateDiscussionPost',
			params: {
				'paperFormatUrl': 'apa-7'
			}
		}
	},
	{
		path: '/create-document/apa/discussion',
		redirect: {
			name: 'UpdateRedirect',
		}
	},
	{
		path: '/create-document/mla/discussion',
		redirect: {
			name: 'UpdateRedirect',
		}
	},

	// Other
	{
		name: 'CreateOther',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateOther'),
		path: '/create-other',
		meta: {
			hideZendesk: true,
		},
	},
	{
		path: '/create-v2-other',
		redirect: {
			name: 'CreateOther',
		}
	},
	{
		path: '/convert-document',
		redirect: {
			name: 'CreateOther'
		},
	},

	// Reference List
	{
		name: 'CreateReferenceList',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateReferenceList'),
		path: '/create-reference-list/:paperFormatUrl',
		props: true,
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
			paperTypeEnum: config.enums.PaperType.APA7_ReferenceList,
		},
	},
	{
		path: '/create-v2-reference-list/14',
		redirect: {
			name: 'CreateReferenceList',
			params: {
				'paperFormatUrl': 'apa-7'
			}
		}
	},

	// Research Paper
	{
		name: 'CreateResearchPaper',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateResearchPaper'),
		path: '/create-research-paper/:paperFormatUrl',
		props: true,
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
			paperTypeEnum: config.enums.PaperType.APA7_StudentPaper,	// default to Student since it's the most common and first tab on that view
		},
	},
	{
		path: '/create-paper-apa7',
		redirect: {
			name: 'UpdateRedirect',
		}
	},
	{
		path: '/create-v2-research-paper/11',
		redirect: {
			name: 'CreateResearchPaper',
			params: {
				'paperFormatUrl': 'apa-7'
			}
		}
	},
	{
		path: '/create-document/apa/research',
		redirect: {
			name: 'UpdateRedirect',
		}
	},
	
	{
		path: '/create-document/mla/research',
		redirect: {
			name: 'UpdateRedirect',
		}
	},
	
	{
		name: 'CreateAPA6Paper',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateAPA6Paper'),
		path: '/create-apa6-research-paper',
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
		},
	},
	{
		name: 'CreateMLA8Paper',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateMLA8Paper'),
		path: '/create-mla8-research-paper',
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
		},
	},
	{
		name: 'CreateBetaTurabian9',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/CreateBetaTurabian9'),
		path: '/create-beta-turabian-9',
		meta: {
			hideZendesk: true,
			isPaperCreate: true,
			paperTypeEnum: config.enums.PaperType.Turabian9_ResearchPaper,	// default to Student since it's the most common and first tab on that view
		},
	},


	// Misc. and Landings
	{
		name:'DocumentCreateLanding',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/DocumentCreateLanding'),
		path: '/document-create-landing',
		meta: {
			hideZendesk: true,
		},
	},
	{
		name: 'DocumentCreateSuccess',
		component: () => import(/* webpackChunkName: "ChunkDocumentCreates" */ '@/views/DocumentCreateSuccess'),
		path: '/document-create-success',
	},
	
]