// form data for the free trial creation part

/*
- freeTrial
-- ex: this.$store.state.freeTrial.x
*/

export default {
	namespaced: true,

	state:{
		email: '',
		firstName: '',
		lastName: '',
		password: '',
		school: '',
	},//e:state

	mutations: {
		SET_EMAIL(state, value){
			state.email = value;
		},
		SET_FIRST_NAME(state, value){
			state.firstName = value;
		},
		SET_LAST_NAME(state, value){
			state.lastName = value;
		},
		SET_PASSWORD(state, value){
			state.password = value;
		},
		SET_SCHOOL(state, value){
			state.school = value;
		},
	},//e:mutations
}
