import enums from '../src/config/enums';

// env detection (only one should be true)
let isDev = false;
let isTest = false;
let isLive = false;

// set app level url 
let appUrl = window.location.origin;

if(appUrl.includes('localhost')){ 
	// Dev
	isDev = true;
} else if(appUrl.includes('testword')){
	// Test
	isTest = true;
} else {
	// Live ?
	isLive = true;
}

// store todays' date so i won't have to calculate it every time
let todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);	// kill the time - i only want the date part of the date object

export default {
	appUrl: appUrl,
	enums: enums,
	emptyText: '---',
	forceResyncBeforeDate: '2023-06-01T07:00:00',	// set a date string here, if lastSyncDate comes before this date, a full force Sync will happen
	isDev: isDev,
	isLive: isLive,
	isTest: isTest,
	platformId: enums.Platform.ADD_IN,
	today: todayDate,
	usePendo: (isLive) ? true : false, // use Pendo - live site only
	useErrorLogger: (isTest || isLive) ? true : false, // use Rollbar - test and live sites only
}