export default [
	{
		name: 'AddUpdatePaymentMethod',
		component: () => import(/* webpackChunkName: "ChunkCart" */ '@/views/Cart/AddUpdatePaymentMethod'),
		path: '/payment-method',
		meta: {
			// layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'PaymentSettings',
		component: () => import(/* webpackChunkName: "ChunkCart" */ '@/views/Cart/PaymentSettings'),
		path: '/cart-step-3',
		meta: {
			// layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'PaymentSettingsPrioritySupport',
		component: () => import(/* webpackChunkName: "ChunkCart" */ '@/views/Cart/PaymentSettingsPrioritySupport'),
		path: '/cart-priority-support',
		meta: {
			// layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'Receipt',
		component: () => import(/* webpackChunkName: "ChunkCart" */ '@/views/Cart/Receipt'),
		path: '/cart-step-4',
		meta: {
			// layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'SubscriptionSettings',
		component: () => import(/* webpackChunkName: "ChunkCart" */ '@/views/Cart/SubscriptionSettings'),
		path: '/cart-step-2',
		meta: {
			// layout: config.enums.Layout.PCOM,
		},
	},
]