/*
- References
-- these all live under the reference, but extracting them here gives be easier
-- ex: this.$store.state.references.x
*/


const maxRecentReferenceTypes = 5;

export default {
	namespaced: true,

	state: {
		// local storage states
		recentTypeIds: [],	// array: contains a list of ids for use with Recent References Tab on create
		

		// non local storage states
		editCitation: {},			// this will be replaced Mid Jan. 2021 with openCitationUID
		editReference: {},	// an open reference the user is editing 
		focusIdOnLoad: '',	// focus on a specific reference control input or namemaker
		openCitationUID: '',		// String: citationUniqueID of the citation a user is editing (this will look it up when the citation edit view is mounted())
		referenceDefaultName: '',	// default name for a reference - create ref step 2
		referenceDefaultValue: '',	// default value for a reference - create ref step 2
		referenceRules: [],	// reference rules engine
		referencesInPaper: [],		// Array: Each is a ReferenceObject
		updateAfterSync: [],	// array of references to update (if refs change in another platform)
	},

	mutations: {
		ADD_TO_UPDATE_AFTER_SYNC(state, newReference){
			state.updateAfterSync.push(newReference);
		},
		RESET_UPDATE_AFTER_SYNC(state){
			state.updateAfterSync = [];
		},

		SET_OPEN_CITATION_UID(state, value) {
			state.openCitationUID = value;
		},

		ADD_REFERENCE_TO_PAPER(state, reference){
			state.referencesInPaper.push(reference)
		},
		editCitation(state, citation) {
			state.editCitation = citation;
		},
		editReference(state, reference) {
			state.editReference = reference;
		},
		focusIdOnLoad(state, focusIdOnLoad) {
			state.focusIdOnLoad = focusIdOnLoad;
		},

		RECENT_TYPE_IDS_ADD(state, refTypeId){
			// 1: trim the array down to the max number
			if(state.recentTypeIds.length > maxRecentReferenceTypes - 1){
				state.recentTypeIds = state.recentTypeIds.slice(0, maxRecentReferenceTypes - 1);
			}

			// 2: push the new type 
			if(!state.recentTypeIds.includes(refTypeId)){
				state.recentTypeIds.push(refTypeId);
			}
		},

		REMOVE_CITATION_FROM_PAPER(state, $opts){
			// find this reference index
			let thisReferenceIndex = state.referencesInPaper.findIndex(ref => ref.referenceUniqueID == $opts.referenceUniqueID);
			if(thisReferenceIndex != -1 && state.referencesInPaper[thisReferenceIndex].citations){
				// find this citation index
				let thisCitationIndex = state.referencesInPaper[thisReferenceIndex].citations.findIndex(cit => cit.citationUniqueID == $opts.citationUniqueID);
				if(thisCitationIndex != -1){
					state.referencesInPaper[thisReferenceIndex].citations.splice(thisCitationIndex,1);
				}
			}
		},
		REMOVE_REFERENCE_FROM_PAPER(state, referenceUniqueID){
			state.referencesInPaper = state.referencesInPaper.filter(ref => ref.referenceUniqueID !== referenceUniqueID);
		},
		referenceDefaultName(state, referenceDefaultName) {
			state.referenceDefaultName = referenceDefaultName;
		},
		referenceDefaultValue(state, referenceDefaultValue) {
			state.referenceDefaultValue = referenceDefaultValue;
		},
		referenceRules(state, referenceRules) {
			state.referenceRules = referenceRules;
		},
		SET_REFERENCES_IN_PAPER(state, value) {
			state.referencesInPaper = value;
		},
	}

}