var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["sidemenu-wrp", { open: _vm.isSidebarOpen }] }, [
    _c(
      "div",
      { staticClass: "w-100 d-block" },
      [
        _c(
          "a",
          {
            staticClass: "close-sidemenu",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeMenu.apply(null, arguments)
              },
            },
          },
          [
            _c("span", {
              staticClass: "svg bi-x-lg",
              attrs: { title: "Open Menu" },
            }),
            _vm._v(" CLOSE\n\t\t"),
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.showDevTools
          ? _c(
              "div",
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showBetaFeatures,
                        expression: "showBetaFeatures",
                      },
                    ],
                    staticClass: "router-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setShowBetaFeatures(false)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tHide Beta Features\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showBetaFeatures,
                        expression: "!showBetaFeatures",
                      },
                    ],
                    staticClass: "router-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setShowBetaFeatures(true)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tShow Beta Features\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "ErrorTests" } },
                  },
                  [_vm._v("\n\t\t\t\tError Tests\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "DeveloperTools" } },
                  },
                  [_vm._v("\n\t\t\t\tDeveloper Tools\n\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.iOS
          ? _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "Startup" } },
                  },
                  [_vm._v("\n\t\t\t\tGetting Started\n\t\t\t")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "router-link",
            attrs: { to: { name: "DocumentCreateLanding" } },
          },
          [_vm._v("\n\t\t\tCreate New Paper\n\t\t")]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "router-link",
            attrs: { to: { name: "PaperSectionsTitlePage" } },
          },
          [
            _vm._v("\n\t\t\tTitle "),
            _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER &&
            (_vm.formatVersionID === _vm.$enums.Format.APA6 ||
              _vm.formatVersionID === _vm.$enums.Format.APA7)
              ? _c("span", [_vm._v("Page")])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER
          ? _c(
              "router-link",
              {
                key: "linkAbstract",
                staticClass: "router-link",
                attrs: { to: { name: "PaperSectionsAbstract" } },
              },
              [_vm._v("Abstract")]
            )
          : _c(
              "a",
              {
                key: "linkNoAbstract",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Abstract")]
            ),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER
          ? _c(
              "router-link",
              {
                key: "linkToc",
                staticClass: "router-link",
                attrs: { to: { name: "PaperSectionsToc" } },
              },
              [_vm._v("Table of Contents")]
            )
          : _c(
              "a",
              {
                key: "linkNoToc",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Table of Contents")]
            ),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER
          ? _c(
              "router-link",
              {
                key: "linkAppendices",
                staticClass: "router-link",
                attrs: { to: { name: "PaperSectionsAppendix" } },
              },
              [_vm._v("Appendices")]
            )
          : _c(
              "a",
              {
                key: "linkNoAppendices",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Appendices")]
            ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "router-link",
            attrs: { to: { name: "ReferencesCitations" } },
          },
          [_vm._v("\n\t\t\tReferences & Citations\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "router-link",
            attrs: {
              to: {
                name: "ResearchNotes",
              },
            },
          },
          [_vm._v("\n\t\t\tResearch Notes\n\t\t")]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.iOS
          ? _c(
              "router-link",
              {
                staticClass: "router-link",
                attrs: { to: { name: "TextStyles" } },
              },
              [_vm._v("\n\t\t\tText & Headings\n\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.documentType !== _vm.$enums.DocumentType.ANNOTATED_BIBLIOGRAPHY &&
        _vm.documentType !== _vm.$enums.DocumentType.REFERENCE_LIST
          ? _c(
              "router-link",
              {
                key: "linkInsertFigure",
                staticClass: "router-link",
                attrs: { to: { name: "InsertFigure" } },
              },
              [_vm._v("Insert Figure")]
            )
          : _c(
              "a",
              {
                key: "linkNoInsertFigure",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Insert Figure")]
            ),
        _vm._v(" "),
        _vm.documentType !== _vm.$enums.DocumentType.ANNOTATED_BIBLIOGRAPHY &&
        _vm.documentType !== _vm.$enums.DocumentType.REFERENCE_LIST
          ? _c(
              "router-link",
              {
                key: "linkInsertTable",
                staticClass: "router-link",
                attrs: { to: { name: "InsertTable" } },
              },
              [_vm._v("Insert Table")]
            )
          : _c(
              "a",
              {
                key: "linkNoInsertTable",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Insert Table")]
            ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.formatVersionID === _vm.$enums.Format.APA7
          ? _c(
              "router-link",
              {
                key: "linkAem",
                staticClass: "router-link",
                attrs: { to: { name: "AdvancedEditing" } },
              },
              [_vm._v("\n\t\t\tAdvanced Editing\n\t\t")]
            )
          : _c(
              "a",
              {
                key: "linkNoAem",
                staticClass: "router-link disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("Advanced Editing")]
            ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.iOS
          ? _c("div", [
              _c(
                "a",
                {
                  staticClass: "router-link",
                  attrs: {
                    href: "https://perrla.zendesk.com/hc/en-us/categories/360001366714-PERRLA-Add-in-BETA-",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
                [_vm._v("Help")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "router-link",
            attrs: { to: { name: "PaperReview" } },
          },
          [_vm._v("\n\t\t\tPaper Review\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "router-link", attrs: { to: { name: "Settings" } } },
          [_vm._v("\n\t\t\tSettings\n\t\t")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }