<!-- Toaster
- loads at the bottom
- so used to display a message after saving a form (etc: Title Page Edit)
- after display, it closes after 2.5s
-->
<template>
	<div :class="['toaster', {'open': isOpen}]" ref="toasterWrp">
		<div :class="['toaster-header toaster-'+type]" v-html="title" />

		<!-- Optional Body Content as HTML -->
		<div v-if="body.length > 0" class="toaster-body">
			<div v-html="body" /><br />
			<div class="d-grid">
				<button type="button" :class="['btn btn-' + buttonType]" @click.prevent="isOpen = false">
					{{ buttonText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ToasterComponent',
	data() {
		return {
			body: '',
			buttonText: 'Got it - thanks!',	// default
			buttonType: 'success',
			isOpen: false,
			isSticky: false,
			title: 'Success!',	// default for now
			type: 'success'		// default
		}
	},
	created() {
		this.emitter.on('toasterOpen', ($opts)=>{
			if($opts.body){
				this.body = $opts.body;
			}
			if($opts.buttonText){
				this.buttonText = $opts.buttonText;
			}
			if($opts.buttonType){
				this.buttonType = $opts.buttonType;
			}
			if($opts.isSticky){
				this.isSticky = $opts.isSticky;
			}
			if($opts.title){
				this.title = $opts.title;
			}
			if($opts.type){
				this.type = $opts.type
			}
			

			// open 
			this.isOpen = true;

			this.$nextTick(()=>{
				let toasterHeight = this.$refs.toasterWrp.clientHeight;
				this.$refs.toasterWrp.style.bottom = -toasterHeight;
			});

			// set close timer
			if(!this.isSticky){
				setTimeout(()=>{
					this.isOpen = false;
				}, 2500);
			}
		});

		this.emitter.on('toasterClose', ($opts)=>{
			this.isOpen = false;
		});
	},
	//mounted() {},
	destroyed(){
		this.emitter.off('toasterClose');
		this.emitter.off('toasterOpen');
	}
}
</script>