import createPersistedState from 'vuex-persistedstate'; //https://github.com/robinvdvleuten/vuex-persistedstate
import Vue from 'vue';
import Vuex from 'vuex';

// store modules
import api from './modules/api';
import cart from './modules/cart';
import classes from './modules/classes';
import customer from './modules/customer';
import forms from './modules/forms';
import freeTrial from './modules/freeTrial';
import gridSorts from './modules/gridSorts';
import loaders from './modules/loaders';
import references from './modules/references';
import referenceDuplicate from './modules/referenceDuplicate';
import referenceRules from './modules/referenceRules';
import researchNotes from './modules/researchNotes';
import thirdPartyLookup from './modules/thirdPartyLookup';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	plugins: [createPersistedState({
		// array of states you want to keep in local storage
		paths: [
			'api.sessionId',
			'api.token',
			'customer.email',
			'gridSorts.addFromLibraryIsByName',
			'hasCreatedPaper',
			'idToken',
			'lastSyncDate',
			'references.recentTypeIds',
			'openReferenceId',
			'redirectTo',
			'refLibCompString',
			'refLibCompStringGenesis',
			'showBetaFeatures',
			'researchNotes.activeResearchNotes',
			'researchNotes.list'
		]
	})],
	
	modules: {
		api,
		cart,
		classes,
		customer,
		forms,
		freeTrial,
		gridSorts,
		loaders,
		references,
		referenceDuplicate,
		referenceRules,
		researchNotes,
		thirdPartyLookup
	},

	state: {
		// localStorage states
		// apiToken: '',
		idToken: '',
		hasCreatedPaper: false,
		lastSyncDate: '', // returns from /Sync and is sent to back when new data is needed 
		openReferenceId: '',	// id of which ref is open (refs and cits view)
		redirectTo: '',
		refLibCompString: '',	// compressed LZ-String of referenceLibrary array
		refLibCompStringGenesis: '',	// compressed LZ-String of referenceLibraryGenesis array
		showBetaFeatures: false,	// uses impersonation and dev/test to show or hide beta features
		
		// non localStorage states
		contributorsDataCopy: [],	// array copy of data for contributors
		documentType: '',
		formatVersionID: 0,	// sets when App.vue is mounted()
		
		iOS: false,			// Word on iPad has special functionality - sets when App.vue is mounted()
		isSidebarOpen: false,
		paperTypeID: 0,	// sets when App.vue is mounted()
		paperGuid: '',
		referenceLibrary: [],	// no longer in localstorage	
		referenceLibraryGenesis: [],	// no longer in localstorage	
		referenceTypeSearchTerm: '',	// create ref step 1 > all ref types > search term
		show3pluRedirectMessage: false,
		useAdvancedMode: false,
	}, //e:state


	mutations: {
		contributorsDataCopy(state, value) {
			state.contributorsDataCopy = value;
		},
		formatVersionID(state, value) {
			state.formatVersionID = value;
		},
		idToken(state, value) {
			state.idToken = value;
		},
		hasCreatedPaper(state, value) {
			state.hasCreatedPaper = value;
		},
		iOS(state, value) {
			state.iOS = value;
		},
		isSidebarOpen(state, value) {
			state.isSidebarOpen = value;
		},
		LAST_SYNC_DATE(state, value) {
			state.lastSyncDate = value;
		},
		openReferenceId(state, value) {
			state.openReferenceId = value;
		},
		paperTypeID(state, value) {
			state.paperTypeID = value;
		},
		redirectTo(state, value) {
			state.redirectTo = value;
		},
		REFERENCE_LIBRARY(state, value) {
			state.referenceLibrary = value;
		},
		REFERENCE_LIBRARY_GENESIS(state, value) {
			state.referenceLibraryGenesis = value;
		},
		refLibCompString(state, value) {
			state.refLibCompString = value;
		},
		refLibCompStringGenesis(state, value) {
			state.refLibCompStringGenesis = value;
		},

		REFERENCE_REMOVE_FROM_LIBRARY(state, referenceUniqueID){
			let thisReferenceFindIndex = state.referenceLibraryGenesis.findIndex((reference)=>{
				return reference.referenceUniqueID == referenceUniqueID
			});
			if(thisReferenceFindIndex != -1){
				state.referenceLibraryGenesis.splice(thisReferenceFindIndex,1);
			}
		},//e:REFERENCE_REMOVE_FROM_LIBRARY
		REFERENCE_TYPE_SEARCH_TERM_SET(state, value) {
			state.referenceTypeSearchTerm = value;
		},
		SET_DOCUMENT_TYPE(state, value) {
			state.documentType = value;
		},
		SET_PAPER_GUID(state, value) {
			state.paperGuid = value;
		},
		SET_SHOW_3PLU_REDIRECT_MESSAGE(state, value) {
			state.show3pluRedirectMessage = value;
		},
		SHOW_BETA_FEATURES(state, value) {
			state.showBetaFeatures = value;
		},
		useAdvancedMode(state, value) {
			state.useAdvancedMode = value;
		},
		
	}, //e:mutations
});