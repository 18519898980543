export default [
	{
		name: 'CitationAddEdit',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CitationAddEdit'),
		path: '/citation/:mode',
		props: true,
		meta: {
			hideZendesk: true,
			requiresPerrla: true
		},
	},
	{
		name: 'CitationAddEditGenesis',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CitationAddEditV2Genesis'),
		path: '/citation-genesis/:mode',
		props: true,
		meta: {
			hideZendesk: true,
			requiresPerrla: true
		},			
	},
	{
		name: 'ConvertGenesisStep1',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/AddFromLibrary/ConvertGenesisStep1'),
		path: '/reference-convert-genesis-1',
		meta: {
			hideZendesk: true,
			requiresPerrla: true
		},
	},
	{
		name: 'ConvertGenesisStep2',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/AddFromLibrary/ConvertGenesisStep2'),
		path: '/reference-convert-genesis-2',
		meta: { hideZendesk: true, requiresPerrla: true},
	},

	{
		name: 'CreateReferenceStep1',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step1'),
		path: '/create-reference-1',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateGenesisReferenceStep1',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/GenesisStep1'),
		path: '/create-genesis-reference-1/:openReferenceTab',
		props: true,
		meta: { hideZendesk: true, requiresPerrla: true},			
	},
	{
		name: 'CreateReferenceStep2',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step2'),
		path: '/create-reference-2',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateGenesisReferenceStep2',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/GenesisStep2'),
		path: '/create-genesis-reference-2/:openReferenceTab/:referenceTypeSlug',
		props: true,
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateStep2ThirdParty',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step2ThirdParty'),
		path: '/create-genesis-reference-2-3plu',
		meta: { hideZendesk: true, requiresPerrla: true},			
	},
	{
		name: 'CreateReferenceStep3',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step3'),
		path: '/create-reference-3',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateGenesisReferenceStep3',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/GenesisStep3'),
		path: '/create-genesis-reference-3/:openReferenceTab/:referenceTypeSlug',
		props: true,
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateStep3ThirdParty',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step3ThirdParty'),
		path: '/create-genesis-reference-3-3plu',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'CreateStep4ThirdParty',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/CreateReference/Step4ThirdParty'),
		path: '/create-genesis-reference-4-3plu',
		meta: { hideZendesk: true, requiresPerrla: true},
	},

	{
		name: 'ReferenceAddFromLibrary',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/AddFromLibrary'),
		path: '/reference-add-from-library',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'ReferenceEdit',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/ReferenceEdit'),
		path: '/reference-edit',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'ReferenceEditGenesis',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/ReferenceEditGenesis'),
		path: '/reference-edit-genesis',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'ReferenceReviewGenesis',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations/ReferenceReviewGenesis'),
		path: '/reference-review-genesis',
		meta: { hideZendesk: true, requiresPerrla: true},
	},
	{
		name: 'ReferencesCitations',
		component: () => import(/* webpackChunkName: "ChunkReferences" */ '@/views/ReferencesCitations'),
		path: '/references-citations',
		meta: {
			cleanCopyRedirect: true,
			hideZendesk: true,
			requiresPerrla: true
		},
	},
	
]