// AS_FullLogout
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		store.commit('loaders/ADD_ID', 'App');

		// Reset Store Items (which resets localStorage automatically)
		store.commit('api/SET_TOKEN', '');
		store.commit('idToken', '');

		store.commit('SHOW_BETA_FEATURES', false);

		store.commit('customer/SET_EMAIL', '');
		store.commit('customer/SET_EXPIRATION_DATE', '');
		store.commit('customer/SET_FIRST_NAME', '');
		store.commit('customer/SET_ID', '0');
		store.commit('customer/SET_INSTITUTION', '');
		store.commit('customer/SET_LAST_NAME', '');
		
		// issue with support impersonating then logging out, but the system keeps previous user's references
		store.commit('LAST_SYNC_DATE', '');
		store.commit('REFERENCE_LIBRARY', []);
		store.commit('refLibCompString', '');
		store.commit('REFERENCE_LIBRARY_GENESIS', []);
		store.commit('refLibCompStringGenesis', '');

		store.commit('researchNotes/CLEAR_LIST');

		store.commit('loaders/REMOVE_ID', 'App');

		return resolve();
	});//e:Promise
}