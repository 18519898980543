/*
- Research Notes
-- these all live under the reference, but extracting them here gives be easier
-- ex: this.$store.state.researchNotes.x
*/

import _has from 'lodash/has';
import store from '@/store';

export default {
	namespaced: true,

	state: {
		// local storage states
		activeResearchNotes: [	// tracks notes actually added to a paper, toggles in ResearchNoteRow of the Paper Editor Drawer
			//{
			//	noteId:
			//	paperGuid: 
			//}
		],

		list: [],	// array: each element will be a modified object for each research note
	},
	
	mutations: {
		ADD_TO_LIST(state, value){
			state.list.push(value);
		},

		CLEAR_LIST(state){
			state.list = [];
		},
		
		RESEACH_NOTE_ADD_ACTIVE(state, noteId){
			let findIndex = state.activeResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeResearchNotes.push({
					noteId: noteId,
					paperGuid: store.state.paperGuid
				});
			}
		},

		RESEACH_NOTE_TOGGLE_ACTIVE(state, noteId){
			let findIndex = state.activeResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeResearchNotes.push({
					noteId: noteId,
					paperGuid: store.state.paperGuid
				});
			} else {
				state.activeResearchNotes.splice(findIndex, 1);	
			}
		},
		
		REMOVE_CITATION_BY_UNIQUE_ID(state, researchNoteUniqueId){
			// look up the index to remove by the research Note Unique Id
			let findResearchNote = state.list.find((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === researchNoteUniqueId.toUpperCase();
			});
			if(findResearchNote){
				findResearchNote.citation = '';
				findResearchNote.citationOutput = '';
			}
		},

		REMOVE_BY_UNIQUE_ID(state, researchNoteUniqueId){
			// look up the index to remove by the research Note Unique Id
			let findIndex = state.list.findIndex((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === researchNoteUniqueId.toUpperCase();
			});
			if(findIndex > -1){
				state.list.splice(findIndex, 1);
			}
		},

		UPDATE_CITATION_BY_UNIQUE_ID(state, $opts){
			// look up the index to remove by the research Note Unique Id
			let findResearchNote = state.list.find((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === $opts.researchNoteUniqueId.toUpperCase();
			});

			if(findResearchNote){
				if($opts.newCitationData === ''){
					// delete
					findResearchNote.citation = '';
					findResearchNote.citationOutput = '';
				} else {
					findResearchNote.citation = JSON.stringify($opts.newCitationData);
					// assume APA7 for now
					if(_has($opts.newCitationData, 'apa7') && _has($opts.newCitationData.apa7, 'first') && $opts.newCitationData.apa7.first !== ''){
						findResearchNote.citationOutput = $opts.newCitationData.apa7.first;
					}
				}
			}
		},
		
		UPDATE_TEXT_BY_UNIQUE_ID(state, $opts){
			// look up the index to remove by the research Note Unique Id
			let findResearchNote = state.list.find((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === $opts.researchNoteUniqueId.toUpperCase();
			});
			if(findResearchNote){
				findResearchNote.note = $opts.newNoteText;
			}
		},
		
	},
}
