var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "show-beta-features": _vm.showBetaFeatures } },
    [
      _c("loader-component", { attrs: { "loader-id": "App" } }),
      _vm._v(" "),
      _c("toaster"),
      _vm._v(" "),
      _c("sidemenu"),
      _vm._v(" "),
      _c("router-view", { staticClass: "pb-2" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }