<template>
	<div v-cloak :class="{'show-beta-features': showBetaFeatures}">
		<!-- Full Screen Loading Message -->
		<loader-component loader-id="App" />

		<toaster />
		<sidemenu />

		<!-- <transition name="fade" mode="out-in" appear> -->
			<router-view class="pb-2" />
		<!-- </transition> -->
		
	</div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import _forEach from 'lodash/forEach';
import ApiS_LogBrowser from '@/services/app/api/logBrowser';
import AS_FullLogout from '@/services/app/fullLogout';
import AS_InitTooltips from '@/services/app/initTooltips';
import config from '@/config';
import LoaderComponent from '@/components/Loader';
import Sidemenu from '@/components/SideMenu';
import Toaster from '@/components/Toaster';
import WordDocProps from '@/helpers/word-document-props.js';

export default {
	name: 'App',
	computed:{
		showBetaFeatures(){
			return this.$store.state.showBetaFeatures;
		}
	},
	methods: {
		fullLogout(){
			AS_FullLogout().then(()=>{
				this.$router.push({
					name: 'Login'
				}).catch(()=>{});
			});
		},
	},
	mounted() {
		// console.log('App() mounted()');

		// flag IE11
		let isIE = !!window.MSInputMethodContext && !!document.documentMode;
		// console.log('isIE');
		// console.log(isIE);

		if(isIE){	
			// this is IE, redirect them out
			// console.log('this is IE, redirect them out');
			this.$router.push({
				name:'UnsupportedBrowser'
			}).catch(()=>{});

		} else {
			// not IE, save to load up
			AS_InitTooltips();
		
			ApiS_LogBrowser();

			// Generic Error Handler (outside of Vue like in ckEditor)
			window.onerror = (message, source, line, column, error) => {
				if(config.useErrorLogger){
					// console.log('Send non vue error to rollbar');
					this.$rollbar.error(error);
				} else {
					console.group('Non Vue Error');
					console.log(message);
					console.log(source);
					console.log(error);
					console.groupEnd();
				}

				if(window.newrelic){
					// console.log('Send non vue error to new relic')
					newrelic.noticeError(error);
				}
			};
			
			// set site theme here since it's not tied the customer account settings
			try {
				let MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
				if(MediaQueryList.matches){
					document.documentElement.setAttribute('data-bs-theme', 'dark');
				} else {
					document.documentElement.setAttribute('data-bs-theme', 'light');
				}
			} catch {
				// console.log('matchMedia catch');
			}

			this.$nextTick(() => {
				if(this.$isLive){
					this.$gtag.linker({
						'domains': ['perrla.com', 'perrlacomplete.com','store.perrla.com','word.perrla.com'],
						'accept_incoming': true,
						'decorate_forms': true
					});
				}
			});
			
			this.$store.commit('iOS', !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
			
			WordDocProps.useAdvancedMode().then((useAdvancedMode) => {
				if(useAdvancedMode){
					this.$store.commit('useAdvancedMode', true);
				}
			});

			// Set store instances from the document props
			WordDocProps.getCustomProp('PaperTypeID').then((value)=>{
				this.$store.commit('paperTypeID', value);
				// parse a document type from the paper type id
				switch(value){
					case config.enums.PaperType.APA7_AnnotatedBibliography:
					case config.enums.PaperType.MLA9_AnnotatedBibliography:
					case config.enums.PaperType.Turabian9_AnnotatedBibliography:
						this.$store.commit('SET_DOCUMENT_TYPE', config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY);
						break;
					case config.enums.PaperType.APA_DiscussionPost:
					case config.enums.PaperType.APA7_DiscussionPost:
					case config.enums.PaperType.MLA_DiscussionPost:
					case config.enums.PaperType.MLA9_DiscussionPost:
					case config.enums.PaperType.Turabian9_DiscussionPost:
						this.$store.commit('SET_DOCUMENT_TYPE', config.enums.DocumentType.DISCUSSION_POST);
						break;
					case config.enums.PaperType.APA7_ReferenceList:
					case config.enums.PaperType.MLA9_ReferenceList:
					case config.enums.PaperType.Turabian9_ReferenceList:
						this.$store.commit('SET_DOCUMENT_TYPE', config.enums.DocumentType.REFERENCE_LIST);
						break;
					default:
						this.$store.commit('SET_DOCUMENT_TYPE', config.enums.DocumentType.RESEARCH_PAPER);
						break;
				}

				WordDocProps.getCustomProp('FormatVersionID').then((value)=>{
					this.$store.commit('formatVersionID', value);

				}).catch((error) => {
					// console.log("WordDocProps.getCustomProp('FormatVersionID')");
					if(error){
						console.log(error);
					}

				});

				WordDocProps.getCustomProp('PaperGUID').then((value)=>{
					this.$store.commit('SET_PAPER_GUID', value);
				});

			}).catch((error) => {
				// console.log("WordDocProps.getCustomProp('PaperTypeID')");
				if(error){
					console.log(error);

				}

			});
		}//e:if:isIE
	},
	components: {
		LoaderComponent,
		Sidemenu,
		Toaster
	}
}
</script>
