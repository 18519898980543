import _has from 'lodash/has';
import AS_InitTooltips from '@/services/app/initTooltips';
import config from '@/config.js'
import { isBefore } from 'date-fns'
import store from '@/store'
import Vue from 'vue';
import VueRouter from 'vue-router';
import WordDocProps from '@/helpers/word-document-props.js';
Vue.use(VueRouter);

// modules
import cart from "./modules/cart";
import documentCreates from "./documentCreates";
import reference from "./reference";

const router = new VueRouter({
	routes: [
		{
			name: 'AdvancedEditing',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/AdvancedEditing'),
			path: '/advanced-editing',
			meta: { hideZendesk: true, requiresPerrla: true},
		},
		...cart,
		{
			name: 'CartDialog',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/CartDialog'),
			path: '/cart-dialog',
		},
		...documentCreates,
		
		{
			name: 'CleanCopyRedirect',	// a landing page for when a user opens their Clean Paper in word
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/CleanCopyRedirect'),
			path: '/create-clean-copy-redirect',
		},
		{
			name: 'CreateCleanCopy',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/CreateCleanCopy'),
			path: '/create-clean-copy',
			meta:{
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: "ErrorTests",
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/ErrorTests'),
			path: '/error-tests',
			meta: { hideZendesk: true},
		},
		{
			name: "DeveloperTools",
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/DeveloperTools'),
			path: '/developer-tools',
			meta: { hideZendesk: true},
		},
		{
			name: 'Error',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Error'),
			path: '/error/:errorType',
			props: true,
		},
		// Redirect (no component / beforeEach redirects to Oidc) - setup this way to OAuth server has a url to go to after successful login
		{
			name: 'Redirect',
			path: '/redirect',
			beforeEnter: (to, from, next) => {
				if(to.query.access_token){
					// valid - store the tokens
					store.commit('api/SET_TOKEN', to.query.access_token);

					localStorage.setItem('pc_token_new_after_redirect', to.query.access_token);
					localStorage.setItem('pc_junk_new_after_redirect', 'junk');

					if(to.query.id_token){
						store.commit('idToken', to.query.id_token);
					}

					if(store.state.redirectTo){
						// redirect to wherever the user was trying to go (then remove)
						let redirectTemp = store.state.redirectTo;
						store.commit('redirectTo', '');
						router.push({
							path: redirectTemp
						}).catch(()=>{});
					} else {
						// there isn't a redirect stored but the user was sent here becasue their token was bad - fully log them out
						store.commit('api/SET_TOKEN', '');
						store.commit('idToken', '');
						store.commit('customer/SET_EMAIL', '');
						store.commit('customer/SET_EXPIRATION_DATE', '');
						store.commit('customer/SET_FIRST_NAME', '');
						store.commit('customer/SET_ID', '0');
						store.commit('customer/SET_INSTITUTION', '');
						store.commit('customer/SET_LAST_NAME', '');

						// issue with support impersonating then logging out, but the system keeps previous user's references
						store.commit('LAST_SYNC_DATE', '');
						store.commit('REFERENCE_LIBRARY', []);
						store.commit('refLibCompString', '');
						store.commit('REFERENCE_LIBRARY_GENESIS', []);
						store.commit('refLibCompStringGenesis', '');
					}
				} else {
					// invalid ?
					router.push({
						name: 'Login'
					}).catch(()=>{});
				}
			}//e:beforeEnter
		},
		{
			name: 'ForgotPassword',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/ForgotPassword'),
			path: '/forgot-password',
		},
		{
			name: 'FreeTrialStart',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/FreeTrialStart'),
			path: '/free-trial-start',
		},
		{
			name: 'FreeTrialVerify',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/FreeTrialVerify'),
			path: '/free-trial-verify/:verifyId',
			props: true,
		},
		{
			name: 'FreeTrialAlternateEmail',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/FreeTrialAlternateEmail'),
			path: '/free-trial-alternate-email',
		},
		{
			name: 'GettingStarted',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/GettingStarted'),
			path: '/getting-started',
		},
		{
			name: 'InsertFigure',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/InsertFigure'),
			path: '/figure',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'InsertTable',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/InsertTable'),
			path: '/table',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'Login',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Login'),
			path: '/login',
		},
		{
			name: 'PaperReview',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/PaperReview'),
			path: '/paper-review',
			meta: {
				cleanCopyRedirect: true,
				hideZendesk: true,
				requiresAuth: true,
				requiresPerrla: true,
			},
		},
		{
			name: 'PaperSectionsAbstract',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/PaperAbstract'),
			path: '/paper-sections-abstract',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'PaperSectionsAppendix',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/PaperAppendix'),
			path: '/paper-sections-appendix',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'PaperSectionsTitlePage',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/PaperTitlePage'),
			path: '/paper-sections-title-page',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'PaperSectionsToc',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/PaperToc'),
			path: '/paper-sections-toc',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		...reference,
		{
			name: 'ResearchNotes',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/ResearchNotes'),
			path: '/research-notes',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'Settings',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Settings'),
			path: '/settings',
			meta: { hideZendesk: true },
		},
		{
			name: 'SettingsUpdateConfirmation',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Settings/UpdateConfirmation'),
			path: '/settings-update-confirmation',
			meta: { hideZendesk: true },
		},
		{
			name: 'Startup',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Startup'),
			path: '/startup',
			meta: {hideZendesk: true},
		},
		{
			name: 'SubscriptionExpired',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/SubscriptionExpired'),
			path: '/expired-subscription',
		},
		{
			name: 'TextStyles',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/TextStyles'),
			path: '/text-styles',
			meta: {
				cleanCopyRedirect: true,
				requiresPerrla: true
			},
		},
		{
			name: 'Tutorial',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/Tutorial'),
			path: '/tutorial',
			meta: { hideZendesk: true},
		},
		{
			name: 'UnsupportedBrowser',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/UnsupportedBrowser'),
			path: '/unsupported-browser',
		},

		{
			name: 'UnsupportedPlatform',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/UnsupportedPlatform'),
			path: '/unsupported-platform',
		},
		{
			name: 'UpdateRedirect',
			component: () => import(/* webpackChunkName: "ChunkCore" */ '@/views/UpdateRedirect'),
			path: '/update-redirect',
		},
	]
});

// Guard
// * note requiresPerrla by default implicitly requires auth as well
router.beforeEach((to, from, next) => {
	// Check if Connected
	if(navigator.onLine){
		// Connected
		if(Office.context.platform == 'OfficeOnline' && config.isLive){
			// Office Online - redirect
			if(to.name === 'UnsupportedPlatform'){
				next();
			} else {
				router.push({
					name:'UnsupportedPlatform'
				}).catch(()=>{});
			}
		} else {
			// Not OfficeOnline - should work
			if(to.path === '/help'){
				// Catch Help and redirect (manifest safeguard)
				window.location = 'https://perrla.zendesk.com/hc/en-us/categories/360001366714-PERRLA-Add-in-BETA-';

			} else {
				
				// Require Login (or Perrla Paper or Clean Copy Redirect)
				if (to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.cleanCopyRedirect) ||  to.matched.some(record => record.meta.requiresPerrla)) {

					// Has this user created a paper yet (it marks as true on the last tutorial slide)
					if(store.state.hasCreatedPaper){
						// Yes has created paper
						
						// Check for Perrla Paper
						if (to.matched.some(record => record.meta.requiresPerrla)) {
							// Requires Perrla Paper

							// A perrla paper should first require the user to be logged in
							// Check for Login
							if(store.state.api.token == ''){
								// No Token Set (user is not logged in)
								store.commit('redirectTo', to.path);
				
								router.push({
									name: 'Login'
								}).catch(()=>{});
							} else {
								// Token Set (user is logged in)
								WordDocProps.getCustomProp('PaperGUID').then((value)=>{
									// Perrla Paper

									// Check subscription expiration date
									const dateObj = new Date(store.state.customer.expirationDate);
									dateObj.setHours(0, 0, 0, 0);

									if(isBefore(dateObj, config.today)){ //2099-01-01T00:00:00
										if(window.$vm.$route.name === 'Settings'){
											// user is still expired and on the expired page
											store.commit('loaders/REMOVE_ID', 'App');
										} else {
											router.push({
												name:'Settings'
											}).catch(()=>{});
										}
										
									} else {
										next();
									}
								}).catch(()=>{
									if(to.matched.some(record => record.meta.cleanCopyRedirect)){
										// check if this is really a clean copy or not
										WordDocProps.getAllCustomProps().then((allProps)=>{
											if(
												(_has(allProps, 'PERRLAExportAsNormal') && allProps.PERRLAExportAsNormal) ||	// PO Simplified
												(_has(allProps, 'ConvertedToSimple') && allProps.ConvertedToSimple)				// Add-In Clean Copy
											){
												router.push({
													name: 'CleanCopyRedirect'
												}).catch(()=>{});
											} else {
												router.push({
													name: 'GettingStarted'
												}).catch(()=>{});
											}
										});//allProps
									} else {
										// Not Perrla Paper
										router.push({
											name: 'GettingStarted'
										}).catch(()=>{});
									}
								});
							}
						
						} else {
							// Does Not Require Perrla Paper

							// Check for Login
							if(store.state.api.token == ''){
								// No Token Set (user is not logged in)
								store.commit('redirectTo', to.path);

								router.push({
									name: 'Login'
								}).catch(()=>{});
							} else {
								// Token Set (user is logged in)
								next();
							}
						}//e:if:perrla paper

					} else {
						// No has not created paper (force tutorial)
						router.push({
							name:'Tutorial'
						}).catch(()=>{});
					}

				} else {
					// Does Not Require Login or Perrla Paper (Catch all, not sure what would use this)
					next();
				}
			}
		}
	} else {
		// Not Connected
		if(to.name === 'error'){
			next();
		} else {
			router.push({
				name:'Error',
				params:{
					errorType: 'connection'
				},
			}).catch(()=>{});
		}
	}
});//e:router.beforeEach

router.afterEach((to, from) => {
	// close any open sidemenus
	store.commit('isSidebarOpen', false);
	store.commit('forms/SET_VALIDATION', []);
	window.$vm.emitter.emit('toasterClose');

	AS_InitTooltips();
	
	window.scrollTo(0,0);
});//e:router.afterEach

export default router;